import { useEffect, useState } from "react";
import useRetryFetch from "../../hooks/useRetryFetch";
import { config } from "../../Environment";

const AIPrompt = ({ prompt }) => {
  const [completion, setCompletion] = useState("");
  const retryFetch = useRetryFetch(prompt);
  useEffect(() => {
    const getCompletion = async () => {
      const response = await retryFetch(
        `${config.API_BASE}/api/ai/completion`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            prompt: prompt,
          }),
        }
      );
      const data = await response.json();
      setCompletion(data.text);
    };
    getCompletion();
  }, [prompt]);

  return (
    <div className="mt-4 text-start">
      {completion ? (
        <div
          className="mt-4"
          dangerouslySetInnerHTML={{ __html: completion }}
        ></div>
      ) : (
        <div className="flex space-x-2 justify-start items-start bg-white">
          <span className="sr-only">Loading...</span>
          <div className="h-5 w-5 bg-black rounded-full animate-bounce [animation-delay:-0.3s]"></div>
          <div className="h-5 w-5 bg-black rounded-full animate-bounce [animation-delay:-0.15s]"></div>
          <div className="h-5 w-5 bg-black rounded-full animate-bounce"></div>
        </div>
      )}
    </div>
  );
};

export default AIPrompt;
