import { useState, useRef, useEffect, useContext, useCallback } from "react";
import List from "./List";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "./ui/sheet";
import { Button } from "./ui/button";
import { Label } from "./ui/label";
import { RadioGroup, RadioGroupItem } from "./ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid /* regular */,
} from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

import { directionService } from "../services/directionService";

import "./EditActivity.css";

import LoggerContext from "../context/LoggerProvider";
import { MyContext } from "./reactflow/MyContextProvider";

import { useTranslation } from "react-i18next";
import {
  Gauge,
  Heading1,
  Heading2,
  ListChecks,
  ListOrdered,
  Minus,
  Braces,
  Text,
  Timer,
  Type,
  WrapText,
  ListPlus,
  Bot,
} from "lucide-react";
import { ScrollArea } from "./ui/scroll-area";
import { Icons } from "./Icons";
import ButtonTooltip from "./ButtonToolTip";
import SortableItem from "./SortableItem";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

function moveElement(arr, oldIndex, newIndex) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
}

function createTempFlowContext(flowContext) {
  let result = [];
  for (let entry of flowContext) {
    result.push({ ...entry });
  }
  return result;
}

function EditActivity({ onSave, onCancel, customData }) {
  const { flowContext, setFlowContext } = useContext(MyContext);

  const { t } = useTranslation();

  const { Logger } = useContext(LoggerContext);

  const convertOldCustomToListData = (oldCustomData) => {
    let items = {};

    if (oldCustomData) {
      if (oldCustomData["richText"]) {
        let newid = "richtext-" + Math.floor(Math.random() * 10000);
        items[newid] = {
          id: newid,
          type: "richtext",
          content: oldCustomData["richText"],
        };
      }

      if (oldCustomData["checkboxes"] && oldCustomData["checkboxes"].length) {
        let newid = "checkbox-" + Math.floor(Math.random() * 10000);
        items[newid] = {
          id: newid,
          type: "checkbox",
          content: {
            label: "",
            key: newid,
            options: oldCustomData["checkboxes"].map((option) => {
              return {
                label: option.label,
                key: option.id,
              };
            }),
          },
        };
      }

      if (oldCustomData["twoDfeedback"]) {
        let newid = "gauge-" + Math.floor(Math.random() * 10000);
        items[newid] = {
          id: newid,
          type: "gauge",
          content: { key: newid },
        };
      }
    }
    let result = {
      items,
      lists: {
        "list-1": {
          id: "list-1",
          title: "",
          itemIds: Object.keys(items),
        },
      },
      listOrder: ["list-1"],
    };

    Logger.debug(result);

    return result;
  };

  const convertExportedToListData = (exported) => {
    let items = {};
    for (let comp of exported) {
      items[comp.id] = comp;
    }

    let result = {
      items,
      lists: {
        "list-1": {
          id: "list-1",
          title: "",
          itemIds: exported.map((comp) => comp.id),
        },
      },
      listOrder: ["list-1"],
    };

    Logger.debug(result);

    return result;
  };

  const [listData, setListData] = useState(
    Array.isArray(customData)
      ? convertExportedToListData(customData)
      : convertOldCustomToListData(customData)
  );
  const [showItemEdit, setShowItemEdit] = useState(false);
  const [itemIdToEdit, setItemIdToEdit] = useState("");
  const [autofocusindex, setAutoFocusIndex] = useState(-1);

  const [direction, setDirection] = useState(document.body.dir);

  const [tempFlowContext, setTempFlowContext] = useState(
    createTempFlowContext(flowContext)
  );

  const [isUnique, setIsUnique] = useState(true);

  const [fieldOption, setFieldOption] = useState("createnew");

  const [reusedKey, setReusedKey] = useState("");

  const itemContentRef = useRef(null);
  const itemLabelRef = useRef(null);
  const itemUniqueDescRef = useRef(null);
  const itemDefaultValueRef = useRef(null);
  const itemMinRef = useRef(null);
  const itemMaxRef = useRef(null);
  const itemStepRef = useRef(null);

  const itemOptionsLabelRef = useRef([]);
  // const itemOptionsKeyRef = useRef([]);
  const itemItemsLabelRef = useRef([]);
  // const itemItemsKeyRef = useRef([]);

  const [tempOptions, setTempOptions] = useState([]);
  const [tempItems, setTempItems] = useState([]);

  const handleDragEnd = (result) => {
    let { source, destination } = result;

    if (!destination) return;

    setListData((oldListData) => {
      let newListData = structuredClone(oldListData);
      let oldIndex = source.index;
      let newIndex = destination.index;
      if (source.droppableId === destination.droppableId) {
        if (oldIndex === newIndex) return newListData;
        let itemIds = newListData.lists[source.droppableId].itemIds;
        moveElement(itemIds, oldIndex, newIndex);
      } else {
        let itemIds_source = newListData.lists[source.droppableId].itemIds;
        let itemIds_destination =
          newListData.lists[destination.droppableId].itemIds;

        // remove item from source list
        let removedItem = itemIds_source.splice(oldIndex, 1)[0];

        // add it to the destination list
        itemIds_destination = [
          ...itemIds_destination.slice(0, newIndex),
          removedItem,
          ...itemIds_destination.slice(newIndex, itemIds_destination.length),
        ];

        newListData.lists[destination.droppableId].itemIds =
          itemIds_destination;
      }
      return newListData;
    });
  };

  const addComponentToComposition = (type) => {
    setListData((oldListData) => {
      let newListData = { ...oldListData };
      let newId = type + Math.floor(Math.random() * 10000);

      while (tempFlowContext.find((entry) => entry.key === newId)) {
        newId = type + Math.floor(Math.random() * 10000);
      }

      let content = t(type);

      switch (type) {
        case "hr":
          content = "";
          break;
        case "textbox":
        case "textarea":
          content = { label: t(type), key: newId, changeable: true };
          break;
        case "checkbox":
          content = {
            label: t(type),
            key: newId,
            changeable: false,
            options: [{ label: t(type) + "_opt1", key: newId + "_opt1" }],
          };
          break;
        case "orderedlist":
          content = {
            label: t(type),
            items: [{ label: t(type) + "_itm1", key: newId + "_itm1" }],
          };
          break;
        case "gauge":
          content = { key: newId, changeable: true };
          break;
        case "timer":
          content = { key: newId, changeable: true };
          break;
        case "slider":
          content = {
            label: t(type),
            key: newId,
            changeable: false,
            min: "0",
            max: "100",
            step: "1",
          };
          break;
      }

      newListData.items[newId] = {
        id: newId,
        type: type,
        content: content,
      };
      newListData.lists["list-1"].itemIds.push(newId);

      switch (type) {
        case "textbox":
        case "textarea":
        case "checkbox":
        case "gauge":
        case "timer":
        case "slider":
          setTempFlowContext((oldTempflowContext) => {
            let newTempFlowContext = structuredClone(oldTempflowContext);
            newTempFlowContext.push({
              key: newId,
              description: newId,
              type: type.startsWith("text") ? "text" : type,
              count: 1,
              used: false,
            });
            return newTempFlowContext;
          });
      }
      setAutoFocusIndex(newListData.lists["list-1"].itemIds.length - 1); // auto focus the label of the newly added element

      Logger.debug("newListData", newListData);

      return newListData;
    });
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 100,
        distance: 8,
        pressThreshold: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    })
  );

  const handleOptionDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setTempOptions((oldTempOptions) => {
        const oldIndex = oldTempOptions.findIndex(
          (item) => item.key === active.id
        );
        const newIndex = oldTempOptions.findIndex(
          (item) => item.key === over.id
        );
        return arrayMove(oldTempOptions, oldIndex, newIndex);
      });
    }
  };

  const handleItemDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setTempItems((oldTempItems) => {
        const oldIndex = oldTempItems.findIndex(
          (item) => item.key === active.id
        );
        const newIndex = oldTempItems.findIndex((item) => item.key === over.id);
        return arrayMove(oldTempItems, oldIndex, newIndex);
      });
    }
  };

  const renderItemContent = () => {
    let item = listData.items[itemIdToEdit];

    if (item) {
      switch (item.type) {
        case "headline1":
        case "headline2":
        case "paragraph":
          return (
            <>
              {item ? (
                <>
                  <label>{t("content")}</label>
                  <input
                    className="builderinput"
                    type="text"
                    ref={itemContentRef}
                    defaultValue={item.content}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          );
        case "textbox":
        case "textarea":
          return (
            <>
              {item ? (
                <>
                  <label>{t("label_text")}</label>
                  <input
                    className="builderinput"
                    type="text"
                    ref={itemLabelRef}
                    defaultValue={item.content.label}
                  />
                  <label>{t("default_value")}</label>
                  <input
                    className="builderinput"
                    type="text"
                    ref={itemDefaultValueRef}
                    defaultValue={
                      tempFlowContext.find(
                        (entry) => entry.key === item.content.key
                      ).defaultValue
                    }
                  />
                  {item.content.changeable ? (
                    <>
                      {reusableFields(item.type, item.content.key).length ? (
                        <RadioGroup
                          value={fieldOption}
                          onValueChange={(val) => {
                            setFieldOption(val);
                            setReusedKey("");
                            setIsUnique(true);
                          }}
                          className="mt-4 mb-4"
                        >
                          <div className="flex items-center space-x-2">
                            <RadioGroupItem value="createnew" id="createnew" />
                            <Label htmlFor="createnew">
                              {t("create_new_field")}
                            </Label>
                          </div>
                          <div className="flex items-center space-x-2">
                            <RadioGroupItem value="reuse" id="reuse" />
                            <Label htmlFor="reuse">{t("reuse_field")}</Label>
                          </div>
                        </RadioGroup>
                      ) : (
                        <></>
                      )}
                      {fieldOption === "createnew" ? (
                        <>
                          <label>{t("unique_description")}</label>
                          <input
                            className="builderinput"
                            type="text"
                            ref={itemUniqueDescRef}
                            onChange={(e) =>
                              checkUniqueness(e, item.content.key)
                            }
                            defaultValue={
                              tempFlowContext.find(
                                (entry) => entry.key === item.content.key
                              ).description
                            }
                          />
                          {!isUnique ? (
                            <>
                              <span className="text-xs text-red-500">
                                {t("description_not_unique")}
                              </span>
                              <br />
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <Select onValueChange={setReusedKey} value={reusedKey}>
                          <SelectTrigger className="max-w-[320px]">
                            <SelectValue placeholder={reusedKey} />
                          </SelectTrigger>
                          <SelectContent>
                            {reusableFields(item.type, item.content.key).map(
                              (variable) => (
                                <SelectItem value={variable.key}>
                                  {variable.description}
                                </SelectItem>
                              )
                            )}
                          </SelectContent>
                        </Select>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          );
        case "checkbox":
          return (
            <>
              {item ? (
                <>
                  <label>{t("label_text")}</label>
                  <input
                    className="builderinput"
                    type="text"
                    ref={itemLabelRef}
                    defaultValue={item.content.label}
                  />
                  <label>{t("unique_description")}</label>
                  <input
                    className="builderinput"
                    type="text"
                    ref={itemUniqueDescRef}
                    onChange={(e) => checkUniqueness(e, item.content.key)}
                    defaultValue={
                      tempFlowContext.find(
                        (entry) => entry.key === item.content.key
                      ).description
                    }
                  />
                  {!isUnique ? (
                    <span className="text-xs text-red-500">
                      {t("description_not_unique")}
                    </span>
                  ) : (
                    <></>
                  )}
                  <hr className="mt-6 mb-4" />
                  <h3 className="text-lg">
                    <label>{t("options")}</label>
                  </h3>
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleOptionDragEnd}
                    modifiers={[restrictToVerticalAxis]}
                  >
                    <SortableContext
                      items={tempOptions.map((opt) => opt.key)}
                      strategy={verticalListSortingStrategy}
                    >
                      <div>
                        {tempOptions.map((option, idx) => (
                          <SortableItem key={option.key} id={option.key}>
                            <div className="builderview">
                              <div className="builderresult">
                                <label>{t("option_label")}</label>
                                <input
                                  className="builderinput"
                                  ref={(el) =>
                                    (itemOptionsLabelRef.current[idx] = el)
                                  }
                                  type="text"
                                  defaultValue={option.label}
                                />
                              </div>
                            </div>
                          </SortableItem>
                        ))}
                      </div>
                    </SortableContext>
                  </DndContext>
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    title={t("add_an_option")}
                    icon={solid("plus")}
                    onClick={() => {
                      setTempOptions((oldTempOptions) => {
                        let newTempOptions = [...oldTempOptions];
                        newTempOptions.push({
                          label: "New Option Label",
                          key: "newoption" + Math.floor(Math.random() * 10000),
                        });
                        return newTempOptions;
                      });
                    }}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          );
        case "orderedlist":
          return (
            <>
              {item ? (
                <>
                  <label>{t("label_text")}</label>
                  <input
                    className="builderinput"
                    type="text"
                    ref={itemLabelRef}
                    defaultValue={item.content.label}
                  />
                  <hr className="mt-6 mb-4" />
                  <h3 className="text-lg">
                    <label>{t("items")}</label>
                  </h3>
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleItemDragEnd}
                    modifiers={[restrictToVerticalAxis]}
                  >
                    <SortableContext
                      items={tempItems.map((item) => item.key)}
                      strategy={verticalListSortingStrategy}
                    >
                      <div>
                        {tempItems.map((olitem, idx) => (
                          <SortableItem key={olitem.key} id={olitem.key}>
                            <div className="builderview">
                              <div className="builderresult">
                                <label>{t("item_label")}</label>
                                <input
                                  className="builderinput"
                                  ref={(el) =>
                                    (itemItemsLabelRef.current[idx] = el)
                                  }
                                  type="text"
                                  defaultValue={olitem.label}
                                />
                              </div>
                            </div>
                          </SortableItem>
                        ))}
                      </div>
                    </SortableContext>
                  </DndContext>
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    title={t("add_an_item")}
                    icon={solid("plus")}
                    onClick={() => {
                      setTempItems((oldTempItems) => {
                        let newTempItems = [...oldTempItems];
                        newTempItems.push({
                          label: "New Item Label",
                          key: "newitem" + Math.floor(Math.random() * 10000),
                        });
                        return newTempItems;
                      });
                    }}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          );
        case "gauge":
        case "timer":
          return (
            <>
              {item ? (
                <>
                  {item.content.changeable ? (
                    <>
                      {reusableFields(item.type, item.content.key).length ? (
                        <RadioGroup
                          value={fieldOption}
                          onValueChange={(val) => {
                            setFieldOption(val);
                            setReusedKey("");
                            setIsUnique(true);
                          }}
                          className="mt-4 mb-4"
                        >
                          <div className="flex items-center space-x-2">
                            <RadioGroupItem value="createnew" id="createnew" />
                            <Label htmlFor="createnew">
                              {t("create_new_field")}
                            </Label>
                          </div>
                          <div className="flex items-center space-x-2">
                            <RadioGroupItem value="reuse" id="reuse" />
                            <Label htmlFor="reuse">{t("reuse_field")}</Label>
                          </div>
                        </RadioGroup>
                      ) : (
                        <></>
                      )}
                      {fieldOption === "createnew" ? (
                        <>
                          <label>{t("unique_description")}</label>
                          <input
                            className="builderinput"
                            type="text"
                            ref={itemUniqueDescRef}
                            onChange={(e) =>
                              checkUniqueness(e, item.content.key)
                            }
                            defaultValue={
                              tempFlowContext.find(
                                (entry) => entry.key === item.content.key
                              ).description
                            }
                          />
                          {!isUnique ? (
                            <>
                              <span className="text-xs text-red-500">
                                {t("description_not_unique")}
                              </span>
                              <br />
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <Select onValueChange={setReusedKey} value={reusedKey}>
                          <SelectTrigger className="max-w-[320px]">
                            <SelectValue placeholder={reusedKey} />
                          </SelectTrigger>
                          <SelectContent>
                            {reusableFields(item.type, item.content.key).map(
                              (variable) => (
                                <SelectItem value={variable.key}>
                                  {variable.description}
                                </SelectItem>
                              )
                            )}
                          </SelectContent>
                        </Select>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          );
        case "slider":
          return (
            <>
              {item ? (
                <>
                  <label>{t("label_text")}</label>
                  <input
                    className="builderinput"
                    type="text"
                    ref={itemLabelRef}
                    defaultValue={item.content.label}
                  />
                  <label>{t("unique_description")}</label>
                  <input
                    className="builderinput"
                    type="text"
                    ref={itemUniqueDescRef}
                    onChange={(e) => checkUniqueness(e, item.content.key)}
                    defaultValue={
                      tempFlowContext.find(
                        (entry) => entry.key === item.content.key
                      ).description
                    }
                  />
                  {!isUnique ? (
                    <span className="text-xs text-red-500">
                      {t("description_not_unique")}
                    </span>
                  ) : (
                    <></>
                  )}
                  <label>{t("min_value")}</label>
                  <input
                    className="builderinput"
                    type="text"
                    ref={itemMinRef}
                    defaultValue={item.content.min}
                  />
                  <label>{t("max_value")}</label>
                  <input
                    className="builderinput"
                    type="text"
                    ref={itemMaxRef}
                    defaultValue={item.content.max}
                  />
                  <label>{t("step_by_value")}</label>
                  <input
                    className="builderinput"
                    type="text"
                    ref={itemStepRef}
                    defaultValue={item.content.step}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          );
        default:
          return <></>;
      }
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    itemOptionsLabelRef.current = itemOptionsLabelRef.current.slice(
      0,
      tempOptions.length
    );
    // itemOptionsKeyRef.current = itemOptionsKeyRef.current.slice(
    //   0,
    //   tempOptions.length
    // );
  }, [tempOptions, itemOptionsLabelRef]);

  useEffect(() => {
    let directionUnsubscribe = directionService
      .getDirectionChange()
      .subscribe(() => {
        setDirection(document.body.dir);
      });

    return () => {
      directionUnsubscribe.unsubscribe();
    };
  }, []);

  const exportCustom = () => {
    let orderedIDs = listData.lists["list-1"].itemIds;

    let result = [];
    for (let id of orderedIDs) {
      result.push(listData.items[id]);
    }
    return result;
  };

  const saveItemContent = (idx, labelValue) => {
    setListData((prevListData) => {
      let newListData = { ...prevListData };
      let newChangedItem = {
        ...newListData.items[newListData.lists["list-1"].itemIds[idx]],
      };

      // is content the string label or is it rather an object with content.label being the label?
      if (typeof newChangedItem.content === "string") {
        newChangedItem.content = labelValue;
      } else {
        let newContentObject = { ...newChangedItem.content };
        newContentObject.label = labelValue;
        newChangedItem.content = newContentObject;
      }

      newListData.items[newListData.lists["list-1"].itemIds[idx]] =
        newChangedItem;
      return newListData;
    });
  };

  const saveOptionItemContent = (itemIdx, labelValue, optIdx) => {
    setListData((prevListData) => {
      let newListData = structuredClone(prevListData);

      newListData.items[
        newListData.lists["list-1"].itemIds[itemIdx]
      ].content.options[optIdx].label = labelValue;

      return newListData;
    });
  };

  const saveItemItemContent = (itemIdx, labelValue, itmIdx) => {
    setListData((prevListData) => {
      let newListData = structuredClone(prevListData);

      newListData.items[
        newListData.lists["list-1"].itemIds[itemIdx]
      ].content.items[itmIdx].label = labelValue;

      return newListData;
    });
  };

  const reusableFields = useCallback(
    (fieldtype, contextKey) => {
      let locFlowContext = structuredClone(tempFlowContext);
      let searchType = fieldtype.startsWith("text") ? "text" : fieldtype;

      locFlowContext = locFlowContext
        .filter((entry) => entry.key !== contextKey) // not the current field itself!
        .filter(
          (entry) =>
            entry.type === searchType || (!entry.type && searchType === "text") // only matching types!
        )
        .sort((a, b) => {
          if (a.description.toUpperCase() < b.description.toUpperCase())
            return -1;
          if (a.description.toUpperCase() > b.description.toUpperCase())
            return 1;
          return 0;
        });
      return locFlowContext;
    },
    [tempFlowContext]
  );

  const checkUniqueness = useCallback((e, contextKey) => {
    let currentItem = tempFlowContext.find((entry) => entry.key === contextKey);
    let otherItem = tempFlowContext.find(
      (entry) => entry.description === e.target.value
    );

    if (otherItem && otherItem !== currentItem) {
      setIsUnique(false);
    } else {
      setIsUnique(true);
    }
  }, []);

  useEffect(() => {
    if (!showItemEdit) {
      setIsUnique(true);
    }
  }, [showItemEdit]);

  return (
    <>
      <div className="flex flex-row gap-5 overflow-hidden">
        <div className="h-full relative flex flex-col items-center gap-1.5 max-h-dvh">
          <h2 className="text-xl font-semibold">{t("components")}</h2>
          <h4 className="text-lg">{t("textual")}</h4>
          <div className="grid grid-cols-2 gap-1">
            <ButtonTooltip
              renderItem={() => (
                <Button
                  onClick={() => addComponentToComposition("richtextnew")}
                >
                  <ListPlus />
                </Button>
              )}
              text={t("rich_text")}
            />
            <ButtonTooltip
              renderItem={() => (
                <Button onClick={() => addComponentToComposition("headline1")}>
                  <Heading1 />
                </Button>
              )}
              text={t("headline1")}
            />
            <ButtonTooltip
              renderItem={() => (
                <Button onClick={() => addComponentToComposition("headline2")}>
                  <Heading2 />
                </Button>
              )}
              text={t("headline2")}
            />
            <ButtonTooltip
              renderItem={() => (
                <Button onClick={() => addComponentToComposition("paragraph")}>
                  <Text />
                </Button>
              )}
              text={t("paragraph")}
            />
            <ButtonTooltip
              renderItem={() => (
                <Button
                  onClick={() => addComponentToComposition("orderedlist")}
                >
                  <ListOrdered />
                </Button>
              )}
              text={t("orderedlist")}
            />
            <ButtonTooltip
              renderItem={() => (
                <Button
                  onClick={() => addComponentToComposition("interpolation")}
                >
                  <Braces />
                </Button>
              )}
              text={t("interpolation")}
            />
            <ButtonTooltip
              renderItem={() => (
                    <Button
                  onClick={() => addComponentToComposition("aiprompt")}
                >
                  <Bot />
                </Button>
              )}
              text={t("aiprompt")}
            />
            <ButtonTooltip
              renderItem={() => (
                <Button onClick={() => addComponentToComposition("hr")}>
                  <Minus />
                </Button>
              )}
              text={t("hr")}
            />
          </div>
          <h4 className="text-lg">{t("interactive")}</h4>
          <div className="grid grid-cols-2 gap-1 mb-4">
            <ButtonTooltip
              renderItem={() => (
                <Button onClick={() => addComponentToComposition("textbox")}>
                  <Type />
                </Button>
              )}
              text={t("textbox")}
            />
            <ButtonTooltip
              renderItem={() => (
                <Button onClick={() => addComponentToComposition("textarea")}>
                  <WrapText />
                </Button>
              )}
              text={t("textarea")}
            />
            <ButtonTooltip
              renderItem={() => (
                <Button onClick={() => addComponentToComposition("checkbox")}>
                  <ListChecks />
                </Button>
              )}
              text={t("checkbox")}
            />
            <ButtonTooltip
              renderItem={() => (
                <Button onClick={() => addComponentToComposition("gauge")}>
                  <Gauge />
                </Button>
              )}
              text={t("gauge")}
            />
            <ButtonTooltip
              renderItem={() => (
                <Button onClick={() => addComponentToComposition("timer")}>
                  <Timer />
                </Button>
              )}
              text={t("timer")}
            />
            <ButtonTooltip
              renderItem={() => (
                <Button
                  className="w-full"
                  onClick={() => addComponentToComposition("slider")}
                >
                  <Icons.slider />
                </Button>
              )}
              text={t("slider")}
            />
          </div>
          <div className="h-full min-w-32 flex items-end">
            <Button
              variant="shine"
              onClick={() => {
                // update flowContext
                setFlowContext(tempFlowContext);
                onSave(exportCustom());
              }}
              className="w-full text-lg"
            >
              {t("save")}
            </Button>
          </div>
        </div>
        {/* <div>
          {fieldOption} - {reusedKey}
        </div> */}
        <ScrollArea
          dir={direction}
          className="h-[770px] w-full"
          ScrollBarClassName="w-0 h-0 bg-transparent"
        >
          <div className="flex flex-row h-full w-full">
            {listData.listOrder.map((listId) => {
              const list = listData.lists[listId];
              const items = list.itemIds.map(
                (itemId) => listData.items[itemId]
              );
              return (
                <List
                  key={listId}
                  list={list}
                  items={items}
                  listData={listData}
                  setListData={setListData}
                  setShowItemEdit={setShowItemEdit}
                  setItemIdToEdit={setItemIdToEdit}
                  setTempOptions={setTempOptions}
                  setTempItems={setTempItems}
                  saveItemContent={saveItemContent}
                  saveOptionItemContent={saveOptionItemContent}
                  saveItemItemContent={saveItemItemContent}
                  autofocusindex={autofocusindex}
                  tempFlowContext={tempFlowContext}
                  setTempFlowContext={setTempFlowContext}
                />
              );
            })}
          </div>
        </ScrollArea>
      </div>
      <Sheet open={showItemEdit} onOpenChange={setShowItemEdit}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>{t("edit_item_content")}</SheetTitle>
          </SheetHeader>
          {renderItemContent()}
          <div className="flex flex-row gap-2 mt-4">
            <Button
              className="inline-block"
              disabled={
                (fieldOption === "createnew" && !isUnique) ||
                (fieldOption === "reuse" && reusedKey === "")
              }
              onClick={() => {
                setTempFlowContext((oldTempFlowContext) => {
                  let newTempFlowContext = structuredClone(oldTempFlowContext);
                  let entry = newTempFlowContext.find(
                    (entry) =>
                      entry.key ===
                      (fieldOption === "createnew"
                        ? listData.items[itemIdToEdit].content.key
                        : reusedKey)
                  );
                  if (entry)
                    entry.defaultValue = itemDefaultValueRef.current?.value;

                  if (listData.items[itemIdToEdit].content.changeable) {
                    if (entry) {
                      if (reusedKey) {
                        entry.count = entry.count + 1;
                        // count down the tentative new element or remov it
                        let tentativeIdx = newTempFlowContext.findIndex(
                          (entry) =>
                            entry.key ===
                            listData.items[itemIdToEdit].content.key
                        );
                        let tentative = newTempFlowContext[tentativeIdx];
                        tentative.count = tentative.count - 1;
                        if (tentative.count === 0) {
                          newTempFlowContext.splice(tentativeIdx, 1);
                        }
                      } else {
                        entry.description = itemUniqueDescRef.current?.value;
                      }
                    }
                  }
                  setFieldOption("createnew");
                  setReusedKey("");
                  return newTempFlowContext;
                });
                setListData((oldListData) => {
                  let newListData = { ...oldListData };
                  switch (newListData.items[itemIdToEdit].type) {
                    case "headline1":
                    case "headline2":
                    case "paragraph":
                      newListData.items[itemIdToEdit].content =
                        itemContentRef.current.value;
                      break;
                    case "textbox":
                    case "textarea":
                      newListData.items[itemIdToEdit].content = {
                        label: itemLabelRef.current.value,
                        // key: itemUniqueDescRef.current.value,
                        key:
                          fieldOption === "createnew"
                            ? newListData.items[itemIdToEdit].content.key
                            : reusedKey,
                        changeable: false,
                      };
                      break;
                    case "checkbox":
                      let newoptions = [];
                      Logger.debug(
                        "REF has",
                        itemOptionsLabelRef.current.length,
                        "elements in it"
                      );
                      for (
                        let i = 0;
                        i < itemOptionsLabelRef.current.length;
                        i++
                      ) {
                        newoptions.push({
                          label: itemOptionsLabelRef.current[i].value,
                          key: tempOptions[i].key,
                        });
                      }
                      newListData.items[itemIdToEdit].content = {
                        label: itemLabelRef.current.value,
                        // key: itemUniqueDescRef.current.value,
                        key: newListData.items[itemIdToEdit].content.key, // retain previous key
                        options: newoptions,
                      };
                      break;
                    case "orderedlist":
                      let newitems = [];
                      Logger.debug(
                        "REF has",
                        itemItemsLabelRef.current.length,
                        "elements in it"
                      );
                      for (
                        let i = 0;
                        i < itemItemsLabelRef.current.length;
                        i++
                      ) {
                        newitems.push({
                          label: itemItemsLabelRef.current[i].value,
                          key: tempItems[i].key,
                        });
                      }
                      newListData.items[itemIdToEdit].content = {
                        label: itemLabelRef.current.value,
                        items: newitems,
                      };
                      break;
                    case "gauge":
                      newListData.items[itemIdToEdit].content = {
                        // key: itemUniqueDescRef.current.value,
                        key:
                          fieldOption === "createnew"
                            ? newListData.items[itemIdToEdit].content.key
                            : reusedKey,
                        changeable: false,
                      };
                      break;
                    case "timer":
                      newListData.items[itemIdToEdit].content = {
                        // key: itemUniqueDescRef.current.value,
                        key:
                          fieldOption === "createnew"
                            ? newListData.items[itemIdToEdit].content.key
                            : reusedKey,
                        changeable: false,
                      };
                      break;
                    case "slider":
                      if (!parseInt(itemMinRef.current.value)) {
                        itemMinRef.current.value = "0";
                      }
                      if (
                        !parseInt(itemMaxRef.current.value) ||
                        parseInt(itemMaxRef.current.value) <=
                          parseInt(itemMinRef.current.value)
                      ) {
                        itemMaxRef.current.value = String(
                          parseInt(itemMinRef.current.value) + 10
                        );
                      }
                      if (
                        !parseInt(itemStepRef.current.value) ||
                        parseInt(itemStepRef.current.value) < 0
                      ) {
                        itemStepRef.current.value = "1";
                      }
                      newListData.items[itemIdToEdit].content = {
                        label: itemLabelRef.current.value,
                        // key: itemUniqueDescRef.current.value,
                        key: newListData.items[itemIdToEdit].content.key, // retain previous key
                        min: itemMinRef.current.value,
                        max: itemMaxRef.current.value,
                        step: itemStepRef.current.value,
                      };
                      break;
                    default:
                  }
                  return newListData;
                });
                setShowItemEdit(false);
                setItemIdToEdit("");
                setTempOptions([]);
                setTempItems([]);
              }}
              data-id={"save"}
              key={0}
              variant={`default`}
            >
              {t("save")}
            </Button>
            <Button
              className="inline-block"
              onClick={() => {
                setFieldOption("createnew");
                setReusedKey("");
                setShowItemEdit(false);
                setItemIdToEdit("");
                setTempOptions([]);
                setTempItems([]);
              }}
              data-id={"cancel"}
              key={1}
              variant={`outline`}
            >
              {t("cancel")}
            </Button>
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
}

export default EditActivity;
